import type { Editor } from '@tiptap/core';
import type { EditorView } from 'prosemirror-view';
import { useRef } from 'react';

import { useCreateMediaGroup } from '@/api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspace } from '@api/workspace';
import { useProcessAssets } from '@hooks/useProcessAssets';
import useWorkspaceId from '@hooks/useWorkspaceId';
import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { v4 } from 'uuid';
import createToast from '@/utils/createToast';

export const useFileUpload = ({
  editor,
  mediaGroup,
  onCreate,
}: {
  editor: Editor | null;
  mediaGroup?: MediaGroupDetailDTO;
  onCreate: (params: { key: string; mediaGroupId: string }) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);

  const canIncreaseUsedStorage = isCapable(
    'useStorage',
    workspace?.workspace?.capabilities
  );

  const maxTotalSize = canIncreaseUsedStorage?.capable
    ? (canIncreaseUsedStorage.limitLeft ?? undefined)
    : undefined;

  const { insert } = useProcessAssets({
    pollInterval: 1500,
    onSuccess: (item) => {
      if (!item?.request.result?.id) {
        createToast({
          titleText: 'Upload failed',
          bodyText: `Failed to upload ${item.file.name}. Please try again later.`,
          iconVariant: 'danger',
        });
        return;
      }
      createMediaGroup({
        assets: [item.request.result.id],
        kind: 'gallery',
        projectId: mediaGroup?.project?.id,
        workspaceId: mediaGroup?.project?.id ? undefined : mediaGroup?.workspace.id,
      }).then((createdMediaGroup) => {
        onCreate({ key: item.key, mediaGroupId: createdMediaGroup.mediaGroupId });
      });
    },
  });

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();

  const handleDrop = (
    view: EditorView,
    event: DragEvent,
    _slice: unknown,
    moved: boolean
  ) => {
    if (!moved && event?.dataTransfer?.files?.[0]) {
      const files = Array.from(event.dataTransfer.files);
      event.stopPropagation();
      event.preventDefault();

      if (!workspaceId) return true;

      const { schema } = view.state;
      const coordinates = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });

      const keys = files.map(() => v4());
      const nodes = [];
      for (const key of keys) {
        const node = schema.nodes['content-block']?.create({
          loading: true,
          'data-ref': key,
        });
        if (node) {
          nodes.push(node);
        }
      }

      if (coordinates && nodes.length) {
        const transaction = view.state.tr.insert(coordinates.pos, nodes);
        view.dispatch(transaction);
      }
      insert({
        workspaceId,
        files,
        keys,
        maxTotalSize,
      });

      return true;
    }
    return false;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from((event.target as HTMLInputElement).files ?? []);
    if (!files.length) return;

    if (!(workspaceId && editor)) return;
    const pos = editor.state.selection.anchor;

    const keys = files.map(() => v4());
    let chain = editor.chain().focus();
    for (const key of keys) {
      chain = chain.insertContentAt(pos, {
        type: 'content-block',
        attrs: {
          loading: true,
          'data-ref': key,
        },
      });
    }

    chain.run();
    insert({
      workspaceId,
      files,
      keys,
      maxTotalSize,
    });

    return true;
  };

  return {
    handleDrop,
    handleInputChange,
    inputRef,
  };
};
